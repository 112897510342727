'use client';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/atom/dropdown-menu';
import { Button } from '@/components/atom/button';
import { BellIcon, MenuIcon, SearchIcon } from '@/components/icons';
import { ChevronDown, X } from 'lucide-react';
import { PRIVATE_NAVBAR_LIST, USER_NAVBAR_LIST } from '@/constants';
import { SignOut } from '@/server/actions/auth.actions';
import Avatar from '@/components/atom/avatar';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@/components/atom/drawer';
import Sidebar from './Sidebar';
import { User } from 'next-auth';
import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/atom/button';
import { usePathname } from 'next/navigation';
import { useQueryClient } from '@tanstack/react-query';

const PrivateNavbar = ({ user }: { user?: User }) => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = React.useState(false);
  const pathname = usePathname();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <nav className="w-full fixed left-0 right-0 top-0 flex shadow-header bg-white z-50 h-14 md:h-[68px] text-sm">
        <div className="flex items-center h-full justify-between w-full max-w-[1280px] mx-auto px-4 lg:px-10">
          <div className="flex items-center gap-10">
            <div className="flex items-center gap-2 md:hidden">
              <Drawer open={open} onOpenChange={() => setOpen(!open)}>
                <DrawerTrigger asChild>
                  <Button className="h-6 w-6 flex bg-transparent" variant="ghost" size="icon">
                    <MenuIcon />
                  </Button>
                </DrawerTrigger>
                <DrawerContent
                  hideClose
                  side="left"
                  className="p-0 py-4 w-fit justify-start md:hidden"
                  overlayClassName="md:hidden"
                >
                  <DrawerHeader>
                    <DrawerTitle className="text-sm text-gray-brand7 space-x-2 flex items-center justify-start">
                      <DrawerClose asChild>
                        <Button variant="ghost" size="icon">
                          <X size={20} />
                        </Button>
                      </DrawerClose>
                      MENU
                    </DrawerTitle>
                  </DrawerHeader>

                  <Sidebar
                    isAuthenticated={!!user}
                    asChild
                    hideCreate
                    onItemClick={() => setOpen(false)}
                  />

                  <div className="flex flex-col items-center flex-1 flex-grow gap-6">
                    {PRIVATE_NAVBAR_LIST.map((item) =>
                      item.external ? (
                        <a key={item.name} href={item.path} target="_blank">
                          {item.name}
                        </a>
                      ) : (
                        <Link
                          key={item.name}
                          href={item.path}
                          //   className={cn(
                          //     'hidden lg:block',
                          //     pathname.includes(item.id) && 'lg:hidden font-medium text-purple-brand',
                          //     (item.id === 'signin' || item.id === 'signup') &&
                          //       'font-medium text-purple-brand'
                          //   )}
                        >
                          {item.name}
                        </Link>
                      )
                    )}
                  </div>
                </DrawerContent>
              </Drawer>

              <Link href="/">
                <Image
                  src="/favicon/android-chrome-192x192.png"
                  width={24}
                  height={24}
                  alt="Fusion Logo"
                  priority
                />
              </Link>
            </div>

            <Link href="/" className="hidden md:block">
              <Image
                src="/assets/fusion/Logocolored.svg"
                width={78.55}
                height={20}
                alt="Fusion Logo"
                priority
              />
            </Link>

            <div className="hidden md:flex items-center flex-1 flex-grow gap-6">
              {PRIVATE_NAVBAR_LIST.map((item) =>
                item.external ? (
                  <a key={item.name} href={item.path} target="_blank">
                    {item.name}
                  </a>
                ) : (
                  <Link key={item.name} href={item.path}>
                    {item.name}
                  </Link>
                )
              )}
            </div>
          </div>

          <div className="flex flex-grow flex-1 items-center justify-end gap-2 lg:gap-6">
            <Button variant="ghost" size="icon">
              <SearchIcon />
            </Button>
            <Button variant="ghost" size="icon" className="relative">
              <BellIcon />
              {/* <span className="h-2 w-2 bg-yellow-brand rounded-full absolute top-2.5 right-3"></span> */}
            </Button>
            {user ? (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button className="flex w-fit gap-2.5 font-semibold" variant="ghost" size="icon">
                    <Avatar
                      fallbackText={user?.name ?? ''}
                      className="h-8 w-8"
                      src={(user?.avatarUrl || user?.image) ?? ''}
                      isOnline
                    />
                    <span className="md:flex items-center justify-between gap-2.5 hidden">
                      {user?.name} <ChevronDown className="ml-auto" size={20} />
                    </span>
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="min-w-[200px] p-4 space-y-3 pb-3">
                  <DropdownMenuItem className="h-9 w-full gap-2.5 ">
                    <Avatar
                      fallbackText={user?.name ?? ''}
                      className="h-10 w-10"
                      src={user?.avatarUrl ?? ''}
                      isOnline
                    />
                    <div className="flex flex-col">
                      <p className="font-semibold">{user?.name}</p>
                      <p className="text-xs">{user?.username}</p>
                    </div>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                  {USER_NAVBAR_LIST.map((item) => (
                    <DropdownMenuItem key={item.name} className="h-9 w-full ">
                      {item.external ? (
                        <a href={item.path} target="_blank">
                          {item.name}
                        </a>
                      ) : (
                        <Link href={item.path}>{item.name}</Link>
                      )}
                    </DropdownMenuItem>
                  ))}
                  <DropdownMenuSeparator />

                  <DropdownMenuItem>
                    <button
                      onClick={async () => {
                        setLoading(true);
                        try {
                          await SignOut();
                          queryClient.cancelQueries();
                          queryClient.clear();
                        } catch (error) {
                        } finally {
                          setLoading(false);
                        }
                      }}
                      className="text-purple-brand font-semibold text-sm"
                    >
                      Log Out
                    </button>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            ) : (
              <>
                <Link
                  href={`${pathname}?ref=signin`}
                  className={cn(buttonVariants({ variant: 'ghost', size: 'sm' }))}
                >
                  Log In
                </Link>
                <Link href="/signup" className={cn(buttonVariants({ size: 'sm' }))}>
                  Sign Up
                </Link>
              </>
            )}
          </div>
        </div>
      </nav>
      {loading && (
        <div className="fixed top-0 bottom-0 left-0 right-0 h-screen w-screen flex items-center justify-center bg-pink-brand-muted/40 z-20">
          Signing out...
        </div>
      )}
    </>
  );
};

export default PrivateNavbar;
