import { cn } from '@/lib/utils';
import React from 'react';
import Img from './img';
import { getRandomText } from '@/utils/generate-random-text';

const Avatar = ({
  src = '',
  fallbackText,
  isOnline,
  className,
  initialsClassName,
  statusClassName,
  hideStatus,
}: {
  src?: string;
  fallbackText: string;
  isOnline?: boolean;
  className?: string;
  statusClassName?: string;
  initialsClassName?: string;
  hideStatus?: boolean;
}) => {
  const initials =
    fallbackText
      ?.split(' ')
      .map((name) => name[0])
      .join('') ?? getRandomText(1);

  return (
    <div className={cn('relative w-16 h-16 rounded-full', className)}>
      {src && src !== 'null' ? (
        <Img
          className="w-full h-full rounded-full object-cover"
          width={400}
          height={400}
          src={src}
          alt="avatar"
        />
      ) : (
        <div className="w-full h-full flex items-center justify-center bg-pink-brand rounded-full">
          <span
            className={cn('text-sm font-semibold text-purple-brand4 uppercase', initialsClassName)}
          >
            {initials?.slice(0, 2)}
          </span>
        </div>
      )}
      {isOnline && (
        <span
          className={cn(
            'absolute -bottom-1 right-1 w-3 h-3 rounded-full border-2 border-white bg-success',
            statusClassName,
            hideStatus && 'hidden'
          )}
        />
      )}
    </div>
  );
};

export default Avatar;
